import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { CallbackComponent } from './views/callback/callback.component';
import { RegistrationComponent } from './views/registration/registration.component';
import { UserLayoutComponent } from './shared/layouts/user-layout/user-layout.component';
import {ProviderAdminLayoutComponent } from './shared/layouts/provider-admin-layout/provider-admin-layout.component';
import { ProviderHistoryComponent } from './views/providers/provider-history.component';
import { ProviderReportComponent } from './views/report/provider-report.component';
import { SelectComponent } from './views/select/select.component';
import { LivenessComponent } from './views/liveness/liveness.component';
import { ForbiddenComponent } from './views/forbidden/forbidden.component';
import { ProviderAttestationComponent } from './views/ProviderAttestation/provider-attestation.component';
import { authPdiUserGuard, authPdiAdminGuard, authUserGuard } from './auth/auth.guard';
import { LogoutComponent } from './views/logout/logout.component';

const routes: Routes = [
  {
    path:'',
    component: UserLayoutComponent,
    children: [
      { path: '', redirectTo: '/logout', pathMatch: 'full',  data: { title: 'Logout' } },
      { path: 'ProviderAttestationForm', component: ProviderAttestationComponent, data: { title: 'Form' }, canActivate: [authUserGuard] },      
      { path: 'select', component: SelectComponent, data: { title: 'Providers' }, canActivate: [authUserGuard] },  
    ]
  },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'login/:usertype', component: LoginComponent, data: { title: 'Login' } },
  { path: 'callback', component: CallbackComponent, data: { title: 'Auth' } },
  { path: 'registration/:registrationCode', component: RegistrationComponent, data: { title: 'Registration' } },
  { path: 'provider', component: UserLayoutComponent, data: { title: 'Login' }, canActivate: [authUserGuard] },
  { path: 'admin', component: ProviderAdminLayoutComponent, data: { title: 'Provider Listing' }, canActivate: [authPdiUserGuard] },  
  { path: 'admin/:attestationPeriod', component: ProviderAdminLayoutComponent, data: { title: 'Provider Listing' }, canActivate: [authPdiUserGuard] },  
  { path: 'history/:providerId', component: ProviderHistoryComponent, data: { title: 'Provider History' }, canActivate: [authPdiUserGuard] },  
  { path: 'report', component: ProviderReportComponent, data: { title: 'Report' }, canActivate: [authPdiUserGuard] },  
  { path: 'forbidden', component: ForbiddenComponent, data: { title: 'Forbidden' } },
  { path: 'forbidden/:messageId', component: ForbiddenComponent, data: { title: 'Forbidden' } },
  { path: 'liveness', component: LivenessComponent,  },
  { path: 'hc',  component: LivenessComponent,  },
  { path: 'logout', component: LogoutComponent, data: { title: 'Logout' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
