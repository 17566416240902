<lte-layout-wrapper>

  <lte-layout-header logoLink="/" headerTitle="Provider Attestations" [isAdminLayoutType]="true">
    <lte-layout-header-logo>Provider Attestation</lte-layout-header-logo>
    <lte-layout-header-logo-mini><b>PA Test</b></lte-layout-header-logo-mini>
    <!-- <app-header-inner> </app-header-inner> -->
  </lte-layout-header>
  <div class="col-md-12 bar"></div>
  <lte-layout-content>
    <div class="pt-0 mt-0" *ngIf="this.currentAndHistoryData$?.history">
      <div class="container">
        <div lte-layout-content-before-header>
        </div>
        <div class="row mt-5">
          <div class="col-sm-12">
            <form [formGroup]="formGroup">
              <div class="row">
                <div class="col-sm-3" style="margin-top: 6px;">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/' +  this.currentAndHistoryData$.current.providerDetails.attestationPeriod ]" class="breadcrumb-link">Providers</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Details
                      </li>
                    </ol>
                  </nav>
                  <!-- <span style="color: #030d68;font-size: 20px;"><b>Provider Details</b></span> -->
                </div>
                <!-- <div class="col-sm-3 text-right">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-5 pl-0 pr-1" style="text-align: right;">
                      <label>Assign To</label>
                    </div>
                    <div class="col-4">
                      <select class="form-control fieldRadius" formControlName="ddlNotificationStatus"
                        [attr.disabled]="!isSaveEnabled ? '': null">
                        <option *ngFor="let status of notificationStatusTypes$"
                          value={{status.statusTypeId.toString()}}>{{status.statusTypeName}}</option>
                      </select>
                      <small *ngIf="!isNotificationStatusValid" class="help-block text-left">Notification status is
                        required.</small>
                    </div>
                  </div>
                </div>
              </div> -->
                <div class="col-sm-9">
                  <div class="row d-lg-flex align-items-lg-center justify-content-lg-end">
                    <div class="col-lg-auto mb-3">
                      <label class="mb-0">Assign To <span class="text-red">*</span></label>
                    </div>
                    <div class="col-lg-auto mb-3">
                      <select class="form-control fieldRadius" formControlName="ddlAssignedTo"
                        [attr.disabled]="!isSaveEnabled ? '': null">
                        <option *ngFor="let assignTo of assignToList$"
                          value={{assignTo.providerAttestationUserId.toString()}}>{{assignTo.firstName}}
                          {{assignTo.lastName}}</option>
                      </select>
                      <small *ngIf="!isAssignToValid" class="help-block text-left">Assign To is
                        required.</small>
                    </div>
                    <div class="col-lg-auto mb-3">
                      <label class="mb-0">Notification Status <span class="text-red">*</span></label>
                    </div>
                    <div class="col-lg-auto mb-3">
                      <select class="form-control fieldRadius" formControlName="ddlNotificationStatus"
                        [attr.disabled]="!isSaveEnabled ? '': null">
                        <option *ngFor="let status of notificationStatusTypes$"
                          value={{status.statusTypeId.toString()}}>{{status.statusTypeName}}</option>
                      </select>
                      <small *ngIf="!isNotificationStatusValid" class="help-block text-left">Notification status is
                        required.</small>
                    </div>
                    <div class="col-lg-auto mb-3">
                      <div class="form-group mb-0">
                        <button #btnEdit type="button" (click)="editNotificationStatus()"
                          class="btn btn-save btn-primary" *ngIf="!isSaveEnabled">Edit</button>
                        <div class="btn-group" *ngIf="isSaveEnabled">
                          <button #btnCancel type="button" (click)="cancelNotificationStatus()"
                            class="btn btn-save btn-secondary">Cancel</button>
                          <button #btnUpdate type="button" (click)="SaveOrUpdateNotification()"
                            class="btn btn-save btn-primary">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-title"></div>
              <div class="card-body py-0">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center justify-content-start">
                    <div class="form-group mb-0">
                      <div class="circle ml-0"> <span class="card-text"> <i class="fa fa-user-md fa-2x"
                            aria-hidden="true"></i></span></div>
                    </div>
                    <div class="form-group ml-2 mb-0">
                      <label class="mb-0">{{this.currentAndHistoryData$.history.providerDetails.firstName}}
                        {{this.currentAndHistoryData$.history.providerDetails.middleName}}
                        {{this.currentAndHistoryData$.history.providerDetails.lastName}},
                        {{this.currentAndHistoryData$.history.providerDetails.degree}}</label>
                      <div>
                        <small><b>NPI:</b></small><small>{{this.currentAndHistoryData$.history.providerDetails.npi.trim()}}</small>&nbsp;&nbsp;&nbsp;&nbsp;
                        <small><b>TIN:</b></small><small>{{this.currentAndHistoryData$.history.providerDetails.tin.trim()}}</small>

                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0" style="color: #030d68;">
                    <label>Attestation Period
                      {{this.currentAndHistoryData$.current.providerDetails.attestationPeriod}}</label>
                  </div>
                  <div class="form-group mb-0" style="text-align: right;color: #030d68;">
                    <a class="btn-text-color pull-right" (click)="getFaxPDF()"><i class="fa fa-fax" placement="top"
                        ngbTooltip="View Manual Fax PDF" aria-hidden="true"></i></a>
                    <a class="btn-text-color pull-right" (click)="getAttestationLink()"><i class="fa fa-envelope"
                        placement="top" ngbTooltip="View Provider Attestation URL"></i></a>
                    <lte-modal #attestationLinkModal submitButtonLabel="Close" closeOnOutSideClick="true"
                      contentWidth="750px" style="text-align:left;">
                      <modal-header>
                        <b>Attestation Link for Email</b></modal-header>
                      <modal-body>
                        <!--<span style="display: flex; flex-direction: row;" > -->
                        <label id="lblAttestationURL">{{attestationLink}}</label>
                        &nbsp;&nbsp;<a class="btn-text-color" (click)="copyURLtoClipboard()"> <i
                            style="margin-left:10px;font-size: 1.25em" class="fa fa-copy" placement="top"
                            ngbTooltip="Copy URL to clipboard" container="body"></i></a>
                        <!--</span>-->
                        <br>
                        <label id="lblURLCopied" style="font-size: 11px; color: black"></label>
                      </modal-body>
                    </lte-modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--SECTION 1a - Provider Information -->
        <div style="margin-top: 15px !important;margin-bottom: -15px !important;">
          <div class="row">
            <div class="form-group col-md-12">
              <div class="card">
                <div class="card-title borderstyle">
                  <span class="headerTitle">Provider Information</span>
                </div>
                <div class="card-body pt-1 pb-0 pl-1 pr-1">
                  <!--Attested data section-->
                  <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="this.providerAttestationData$?.attestedDate">
                    <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                      <label>Attested Data on {{this.providerAttestationData$.attestedDate | date:'MM/dd/yyyy'}}</label>
                    </div>
                  </div>
                  <form [formGroup]="formGroup"
                    *ngIf="providerInfoChanged && this.providerAttestationData$?.attestedDate">
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="form-group col-md-3 col-xs-12">
                        <label>Birth Date </label>
                        <input maxlength="10" type="text" class="form-control"
                          [ngClass]="{'valueChanged' : providerChanged}" mask="M0/d0/0000" [clearIfNotMatch]="true"
                          placeholder="mm/dd/yyyy" formControlName="pi_birthdate" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Gender </label>
                        <input maxlength="10" type="text" class="form-control"
                          [ngClass]="{'valueChanged' : genderChanged}" placeholder="Gender"
                          formControlName="pi_gender" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Provider Email </label>
                        <input maxlength="50" type="text" class="form-control"
                          [ngClass]="{'valueChanged' : emailChanged}" placeholder="example@domain.com"
                          formControlName="pi_email" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Office Email</label>
                        <input maxlength="50" type="text" class="form-control"
                          [ngClass]="{'valueChanged' : officeEmailChanged}" placeholder="example@domain.com"
                          formControlName="pi_officeemail" />
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Organizational NPI</label>
                        <input maxlength="50" type="text" class="form-control"
                          [ngClass]="{'valueChanged' : organnizationalNpiChanged}" placeholder="Organizational NPI"
                          formControlName="pi_organnizationalnpi" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>EMR</label>
                        <input maxlength="10" type="text" class="form-control" [ngClass]="{'valueChanged' : emrChanged}"
                          placeholder="EMR" formControlName="pi_emr" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Telehealth</label>
                        <input maxlength="10" type="text" class="form-control"
                          [ngClass]="{'valueChanged' : telehealthChanged}" placeholder="Telehealth"
                          formControlName="pi_telehealth" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Degree(s)<small>(Comma separated)</small></label>
                        <input maxlength="10" type="text" class="form-control"
                          [ngClass]="{'valueChanged' : degreeChanged}" placeholder="Degree"
                          formControlName="pi_degree" />
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Language(s) <small>(Comma separated)</small></label>
                        <textarea maxlength="500" rows="2" cols="20" name="text"
                          [ngClass]="{'valueChanged' : languagesChanged}" placeholder="Languages" class="form-control"
                          formControlName="pi_language" alphabetWithSpaceCommaOnly></textarea>
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Hospital Surgery Center Affiliation </label>
                        <textarea maxlength="500" rows="2" cols="20" name="text"
                          [ngClass]="{'valueChanged' : hosSurgAffChanged}"
                          placeholder="Hospital Surgery Center Affiliation" class="form-control"
                          formControlName="pi_hospitalSurgeryCenterAffiliation"></textarea>
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Age Restrictions</label>
                        <div class="container">
                          <div class="row">
                            <div class="col-sm pl-0">
                              <input maxlength="3" type="text" class="form-control"
                                [ngClass]="{'valueChanged' : ageRestrictionsChanged}" mask="0*" placeholder="Min"
                                formControlName="pi_ageMin" />
                            </div>
                            <span>-</span>
                            <div class="col-sm ">
                              <input maxlength="3" type="text" class="form-control"
                                [ngClass]="{'valueChanged' : ageRestrictionsChanged}" mask="0*" placeholder="Max"
                                formControlName="pi_ageMax" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-3 col-xs-12" style="padding-top: 30px;">
                        <div class="card mt-10"
                          [ngClass]="{'valueChanged' : isPCPChanged || isSpecialistChanged || isSdaOfferedChanged }">
                          <div class="card-body pt-1 pb-1 pl-0 pr-1" style="padding: 1px !important;">
                            <div class="row" style="margin-left: 10px;">
                              <input type="checkbox" name="pi_chkPCP" id="pi_chkPCP"
                                formControlName="pi_chkPCP" /><span>&nbsp;&nbsp;&nbsp;PCP</span>
                            </div>
                            <div class="row" style="margin-left: 10px;">
                              <input type="checkbox" name="pi_chkSpecialty" id="pi_chkSpecialty"
                                formControlName="pi_chkSpecialty" /><span>&nbsp;&nbsp;&nbsp;Specialist</span>
                            </div>
                            <div class="row" style="margin-left: 10px;">
                              <input type="checkbox" name="pi_chksdao" id="pi_chksdao"
                                formControlName="pi_chksdao" /><span>&nbsp;&nbsp;&nbsp;Same day access offered</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <!--Original data section-->
                  <div class="row m-0 p-0 m-0"
                    *ngIf="providerInfoChanged && this.providerAttestationData$?.attestedDate">
                    <div class="form-group col-md-12 pt-1 pb-1" style="color: #030d68;">
                      <label style="margin-top:15px;margin-right: 25px;">Original Data on
                        {{this.currentAndHistoryData$.history.providerDetails.createdDate | date:'MM/dd/yyyy'}}</label>
                    </div>
                  </div>
                  <form [formGroup]="formGroup">
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="form-group col-md-3 col-xs-12">
                        <label>Birth Date </label>
                        <input maxlength="10" type="text" class="form-control" mask="M0/d0/0000"
                          [clearIfNotMatch]="true" placeholder="mm/dd/yyyy" formControlName="pio_birthdate" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Gender </label>
                        <input maxlength="10" type="text" class="form-control" placeholder="Gender"
                          formControlName="pio_gender" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Provider Email </label>
                        <input maxlength="50" type="text" class="form-control" placeholder="example@domain.com"
                          formControlName="pio_email" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Office Email</label>
                        <input maxlength="50" type="text" class="form-control" placeholder="example@domain.com"
                          formControlName="pio_officeemail" />
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Organizational NPI</label>
                        <input maxlength="50" type="text" class="form-control" placeholder="Organizational NPI"
                          formControlName="pio_organnizationalnpi" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>EMR</label>
                        <input maxlength="10" type="text" class="form-control" placeholder="EMR"
                          formControlName="pio_emr" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Telehealth</label>
                        <input maxlength="10" type="text" class="form-control" placeholder="Telehealth"
                          formControlName="pio_telehealth" />
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Degree(s)<small>(Comma separated)</small></label>
                        <input maxlength="10" type="text" class="form-control" placeholder="Degree"
                          formControlName="pio_degree" />
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Language(s) <small>(Comma separated)</small></label>
                        <textarea maxlength="500" rows="2" cols="20" name="text" placeholder="Languages"
                          class="form-control" formControlName="pio_language" alphabetWithSpaceCommaOnly></textarea>
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Hospital Surgery Center Affiliation </label>
                        <textarea maxlength="500" rows="2" cols="20" name="text"
                          placeholder="Hospital Surgery Center Affiliation" class="form-control"
                          formControlName="pio_hospitalSurgeryCenterAffiliation"></textarea>
                      </div>
                      <div class="form-group col-md-3 col-xs-12 ">
                        <label>Age Restrictions </label>
                        <div class="container">
                          <div class="row">
                            <div class="col-sm pl-0">
                              <input maxlength="3" type="text" class="form-control" mask="0*" placeholder="Min"
                                formControlName="pio_ageMin" />
                            </div>
                            <span>-</span>
                            <div class="col-sm ">
                              <input maxlength="3" type="text" class="form-control" mask="0*" placeholder="Max"
                                formControlName="pio_ageMax" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-3 col-xs-12" style="padding-top: 30px;">
                        <div class="card disable">
                          <div class="card-body pt-1 pb-1 pl-0 pr-1" style="padding: 1px !important;">
                            <div class="row" style="margin-left: 10px;">
                              <input type="checkbox" name="pi_chkPCP" id="pio_chkPCP"
                                formControlName="pio_chkPCP" /><span>&nbsp;&nbsp;&nbsp;PCP</span>
                            </div>
                            <div class="row" style="margin-left: 10px;">
                              <input type="checkbox" name="pi_chkSpecialty" id="pio_chkSpecialty"
                                formControlName="pio_chkSpecialty" /><span>&nbsp;&nbsp;&nbsp;Specialist</span>
                            </div>
                            <div class="row" style="margin-left: 10px;">
                              <input type="checkbox" name="pi_chksdao" id="pio_chksdao"
                                formControlName="pio_chksdao" /><span>&nbsp;&nbsp;&nbsp;Same day access offered</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form [formGroup]="formGroup">
          <!--SECTION 1C Provider contract information section-->
          <div style="margin-top: 15px !important;">
            <div class="row p-t-0 m-t-0">
              <!--SECTION 1C Contract Information-->
              <div class="col-md-6">
                <div class="card">
                  <div class="card-title borderstyle ">
                    <span class="headerTitle">Contract Information</span>
                  </div>
                  <div class="card-body pt-1 pb-1 pl-1 pr-1">
                    <!--Attested data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label>Attested Data on {{this.providerAttestationData$.attestedDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1"
                      *ngIf="this.contractInfoChanged && this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12">
                        <div class="conainer">
                          <b><span class="labelHeader">HMO Information</span></b>
                          <div class="row">
                            <div class="col-6">HMO Commercial</div>
                            <div class="col-6">HMO Senior</div>
                          </div>
                          <div class="row">
                            <div class="col-6"><input type="Text"
                                [ngClass]="{'valueChanged' : this.providerPanelStatusToDisplayChanged}"
                                class="form-control" formControlName="medicalEnrollmentStatus" /></div>
                            <div class="col-6"><input type="Text"
                                [ngClass]="{'valueChanged' : this.providerMedicareStatusToDisplayChanged}"
                                class="form-control" formControlName="medicareEnrollmentStatus" /></div>
                          </div>
                        </div>
                        <br>
                        <div class="conainer">
                          <b><span class="labelHeader">CalOptima / IEHP Information</span></b>
                          <div class="row">
                            <div class="col-6">Medi-Cal</div>
                            <div class="col-6">Credentialing Date</div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <input type="Text"
                                [ngClass]="{'valueChanged' : this.providerMedicalStatusToDisplayChanged}"
                                class="form-control" formControlName="panelStatus" />
                            </div>
                            <div class="col-6">
                              <input type="Text" [ngClass]="{'valueChanged' : this.credentialingDateChanged}"
                                class="form-control" formControlName="credentialingDate" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Original data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1"
                      *ngIf="contractInfoChanged && this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label style="margin-top:15px;margin-right: 25px;">Original Data on
                          {{this.currentAndHistoryData$.history.providerDetails.createdDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="col-md-12 pt-1 pb-1">
                        <div class="conainer">
                          <b><span class="labelHeader">HMO Information</span></b>
                          <div class="row">
                            <div class="col-6">HMO Commercial</div>
                            <div class="col-6">HMO Senior</div>
                          </div>
                          <div class="row">
                            <div class="col-6"><input type="Text" class="form-control"
                                formControlName="medicalEnrollmentStatus_o" /></div>
                            <div class="col-6"><input type="Text" class="form-control"
                                formControlName="medicareEnrollmentStatus_o" /></div>
                          </div>
                        </div>
                        <br>
                        <div class="conainer">
                          <b><span class="labelHeader">CalOptima / IEHP Information</span></b>
                          <div class="row">
                            <div class="col-6">Medi-Cal</div>
                            <div class="col-6">Credentialing Date</div>
                          </div>
                          <div class="row">
                            <div class="col-6"><input type="Text" class="form-control"
                                formControlName="panelStatus_o" />
                            </div>
                            <div class="col-6"><input type="Text" class="form-control"
                                formControlName="credentialingDate_o" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--SECTION 5 Specialty-->
              <div class="col-md-6">
                <div class="card mt-10"> <!--style="min-height: 372px; max-height: 646px; overflow-y: auto;"-->
                  <div class="card-title borderstyle ">
                    <span class="headerTitle">Specialty</span>
                  </div>
                  <div class="card-body pt-1 pb-1 pl-0 pr-1">
                    <!--Attested data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="this.providerAttestationData$?.attestedDate">
                      <div class="form-group col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label>Attested Data on {{this.providerAttestationData$.attestedDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row m-0 p-0 m-0" *ngIf="specialityInfoChanged">
                      <div class="form-group col-md-12 pt-1 pb-1">
                        <div
                          [ngClass]="{'valueChanged' : specialityChanged(this.currentAndHistoryData$.history.specialtyDetails, Specialty.specialty) }"
                          class="row underline"
                          *ngFor="let Specialty of this.currentAndHistoryData$?.current?.specialtyDetails?.sort()">
                          <div class="col">
                            {{Specialty.specialty}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Original data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1"
                      *ngIf="specialityInfoChanged && this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label style="margin-top:15px;margin-right: 25px;">Original Data on
                          {{this.currentAndHistoryData$.history.providerDetails.createdDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="col-md-12 pt-1 pb-1">
                        <div class="row underline"
                          *ngFor="let Specialty of this.currentAndHistoryData$?.history?.specialtyDetails?.sort()">
                          <div class="col">
                            {{Specialty.specialty}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SECTION 1B - Provider License -->
          <div style="margin-top: 15px !important;">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-title borderstyle ">
                    <span class="headerTitle">License(s)</span>
                  </div>
                  <div class="card-body pt-1 pb-1 pl-1 pr-1">
                    <!--Attested data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label>Attested Data on {{this.providerAttestationData$.attestedDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="this.licenseInfoChanged && this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1">
                        <div class="row">
                          <div class="form-group col-md-2">
                            <label>Medi-Cal #</label>
                            <input type="Text" [ngClass]="{'valueChanged' : this.mediCalNumChanged}"
                              class="form-control" formControlName="mediCalNum" />
                          </div>
                          <div class="form-group col-md-2">
                            <label>Medicare #</label>
                            <input type="Text" [ngClass]="{'valueChanged' : this.medicareNumChanged}"
                              class="form-control" formControlName="medicareNum" />
                          </div>
                          <!-- <div class="form-group col-md-2">
                            <label>UPIN #</label>
                            <input type="Text" [ngClass]="{'valueChanged' : this.upinChanged}" class="form-control"
                              formControlName="upin" />
                          </div> -->
                          <div class="form-group col-md-2">
                            <label>DEA #</label>
                            <input type="Text" [ngClass]="{'valueChanged' : this.deaChanged}" class="form-control"
                              formControlName="dea" />
                          </div>
                          <div class="form-group col-md-2">
                            <label>State License #</label>
                            <input type="Text" [ngClass]="{'valueChanged' : this.stateLicenseChanged}"
                              class="form-control" formControlName="stateLicense" />
                          </div>
                          <div class="form-group col-md-2">
                            <label>State License Exp Date</label>
                            <input type="Text" [ngClass]="{'valueChanged' : this.licenseExpirationChanged}"
                              class="form-control" formControlName="licenseExpiration" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Original data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1"
                      *ngIf="this.licenseInfoChanged && this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label>Original Data on {{this.currentAndHistoryData$.history.providerDetails.createdDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="col-md-12 pt-1 pb-1">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label>Medi-Cal #</label>
                            <input type="Text" class="form-control" formControlName="mediCalNum_o" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Medicare #</label>
                            <input type="Text" class="form-control" formControlName="medicareNum_o" />
                          </div>
                          <!-- <div class="form-group col-md-2">
                            <label>UPIN #</label>
                            <input type="Text" class="form-control" formControlName="upin_o" />
                          </div> -->
                          <div class="form-group col-md-2">
                            <label>DEA #</label>
                            <input type="Text" class="form-control" formControlName="dea_o" />
                          </div>
                          <div class="form-group col-md-2">
                            <label>State License #</label>
                            <input type="Text" class="form-control" formControlName="stateLicense_o" />
                          </div>
                          <div class="form-group col-md-2">
                            <label>State License Exp Date</label>
                            <input type="Text" class="form-control" formControlName="licenseExpiration_o" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SECTION 2 - Board Training(s) -->
          <div style="margin-top: 15px !important;">
            <div class="row ">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-title borderstyle ">
                    <span class="headerTitle">Board Training(s)</span>
                  </div>
                  <div class="card-body pt-1 pb-1 pl-1 pr-1">
                    <!--Attested data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label>Attested Data on {{this.providerAttestationData$.attestedDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1"
                      *ngIf="this.boardInfoChanged && this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label>Board Name</label>
                            <input type="Text"
                              [ngClass]="{'valueChanged': this.currentAndHistoryData$.current.boardDetails[0].boardName != this.currentAndHistoryData$.history.boardDetails[0].boardName}"
                              class="form-control" formControlName="boardName" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Board Certification Status</label>
                            <input type="Text"
                              [ngClass]="{'valueChanged': this.currentAndHistoryData$.current.boardDetails[0].boardStatus != this.currentAndHistoryData$.history.boardDetails[0].boardStatus}"
                              class="form-control" formControlName="boardStatus" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Effective Date</label>
                            <input type="Text"
                              [ngClass]="{'valueChanged': this.currentAndHistoryData$.current.boardDetails[0].effectiveDate != this.currentAndHistoryData$.history.boardDetails[0].effectiveDate}"
                              class="form-control" formControlName="effectiveDate" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Expiration Date</label>
                            <input type="Text"
                              [ngClass]="{'valueChanged': this.currentAndHistoryData$.current.boardDetails[0].expirationDate != this.currentAndHistoryData$.history.boardDetails[0].expirationDate}"
                              class="form-control" formControlName="expirationDate" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Original data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1"
                      *ngIf="boardInfoChanged && this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label style="margin-top:15px;margin-right: 25px;">Original Data on
                          {{this.currentAndHistoryData$.history.providerDetails.createdDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="col-md-12 pt-1 pb-1">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label>Board Name</label>
                            <input type="Text" class="form-control" formControlName="boardName_o" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Board Certification Status</label>
                            <input type="Text" class="form-control" formControlName="boardStatus_o" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Effective Date</label>
                            <input type="Text" class="form-control" formControlName="effectiveDate_o" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Expiration Date</label>
                            <input type="Text" class="form-control" formControlName="expirationDate_o" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SECTION 3 - Provider Liabilty/Malpractice Insurance section-->
          <div style="margin-top: 15px !important;">
            <div class="row ">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-title borderstyle ">
                    <span class="headerTitle">Liability / Malpractice Insurance(s)</span>
                  </div>
                  <div class="card-body pt-1 pb-1 pl-1 pr-1">
                    <!--Attested data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label>Attested Data on {{this.providerAttestationData$.attestedDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1"
                      *ngIf="this.liabilityInfoChanged && this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label>Carrier</label>
                            <input type="Text"
                              [ngClass]="{'valueChanged': this.currentAndHistoryData$.current.insuranceDetails[0].carrierName != this.currentAndHistoryData$.history.insuranceDetails[0].carrierName}"
                              class="form-control" formControlName="carrierName" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Policy No</label>
                            <input type="Text"
                              [ngClass]="{'valueChanged': this.currentAndHistoryData$.current.insuranceDetails[0].policyNo != this.currentAndHistoryData$.history.insuranceDetails[0].policyNo}"
                              class="form-control" formControlName="policyNo" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Expiration Date</label>
                            <input type="Text"
                              [ngClass]="{'valueChanged': this.currentAndHistoryData$.current.insuranceDetails[0].expirationDate != this.currentAndHistoryData$.history.insuranceDetails[0].expirationDate}"
                              class="form-control" formControlName="liabilityExpirationDate" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Amount($)</label>
                            <input type="Text"
                              [ngClass]="{'valueChanged': this.currentAndHistoryData$.current.insuranceDetails[0].amount != this.currentAndHistoryData$.history.insuranceDetails[0].amount}"
                              class="form-control" formControlName="liabilityAmount" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Original data section-->
                    <div class="row mt-0 mb-0 ml-1 mr-1"
                      *ngIf="liabilityInfoChanged && this.providerAttestationData$?.attestedDate">
                      <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                        <label style="margin-top:15px;margin-right: 25px;">Original Data on
                          {{this.currentAndHistoryData$.history.providerDetails.createdDate |
                          date:'MM/dd/yyyy'}}</label>
                      </div>
                    </div>
                    <div class="row mt-0 mb-0 ml-1 mr-1">
                      <div class="col-md-12 pt-1 pb-1">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label>Carrier</label>
                            <input type="Text" class="form-control" formControlName="carrierName_o" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Policy No</label>
                            <input type="Text" class="form-control" formControlName="policyNo_o" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Expiration Date</label>
                            <input type="Text" class="form-control" formControlName="liabilityExpirationDate_o" />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Amount($)</label>
                            <input type="Text" class="form-control" formControlName="liabilityAmount_o" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SECTION 4 - Office Information-->
          <div style="margin-top: 15px !important;">
            <div class="row ">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-title borderstyle">
                    <span class="headerTitle">Office Information</span>
                  </div>
                  <div class="card-body pt-1 pb-1 pl-1 pr-1">
                    <div>
                      <!--Attested data section-->
                      <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="this.providerAttestationData$?.attestedDate">
                        <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                          <label>Attested Data on {{this.providerAttestationData$.attestedDate |
                            date:'MM/dd/yyyy'}}</label>
                        </div>
                      </div>
                      <div class="row mt-0 mb-0 ml-1 mr-1" *ngIf="locationInfoChanged && this.providerAttestationData$?.attestedDate">
                        <div class="col-md-12 pt-1 pb-0">
                          <div class="table-responsive">
                            <table class="table table-striped">
                              <thead class="m-b-0 m-t-0">
                                <tr class="d-flex">
                                  <th class="col-sm-1">Type</th>
                                  <th class="col-sm-2">Address<small>(Line 1)</small></th>
                                  <th class="col-sm-2">Address <small>(Line 2)</small></th>
                                  <th class="col-sm-1">City</th>
                                  <th class="col-sm-1">State</th>
                                  <th class="col-sm-1">Zip</th>
                                  <th class="col-sm-1">Phone</th>
                                  <th class="col-sm-1">Fax</th>
                                  <th class="col-sm-2">Office Hours</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="d-flex"
                                  *ngFor="let location of currentAndHistoryData$.current.locationDetails">
                                  <td class="col-sm-1" [ngClass]="{'valueChanged' : locationTypeChanged(location)}">
                                    {{location.locationType}}</td>
                                  <td class="col-sm-2" [ngClass]="{'valueChanged' : streetAddress1Changed(location)}">
                                    {{location.streetAddress1}}</td>
                                  <td class="col-sm-2" [ngClass]="{'valueChanged' : streetAddress2Changed(location)}">
                                    {{location.streetAddress2}}</td>
                                  <td class="col-sm-1" [ngClass]="{'valueChanged' : cityChanged(location)}">
                                    {{location.city}}</td>
                                  <td class="col-sm-1" [ngClass]="{'valueChanged' : stateChanged(location)}">
                                    {{location.state}}</td>
                                  <td class="col-sm-1" [ngClass]="{'valueChanged' : zipChanged(location)}">
                                    {{location.zip}}</td>
                                  <td class="col-sm-1" [ngClass]="{'valueChanged' : phoneChanged(location)}">
                                    {{location.phone | formatPhone}}</td>
                                  <td class="col-sm-1" [ngClass]="{'valueChanged' : faxChanged(location)}">
                                    {{location.fax | formatPhone}}</td>
                                  <td class="col-sm-2" [ngClass]="{'valueChanged' : officeHoursChanged(location)}">
                                    {{convertTime(location.monHours)}} <div *ngIf="location.locationType!='Billing'"
                                      class="btncircle textCenter m-0" title="Click to view details"><a
                                        (click)="viewOfficeHoursModal(location.locationId ?? 0, false)"><i
                                          class="fa fa-eye" aria-hidden="true"></i></a></div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!--Original data section-->
                      <div class="row mt-0 mb-0 ml-1 mr-1"
                        *ngIf="locationInfoChanged && this.providerAttestationData$?.attestedDate">
                        <div class="col-md-12 pt-1 pb-1" style="color: #030d68;">
                          <label style="margin-top:15px;margin-right: 25px;">Original Data on
                            {{this.currentAndHistoryData$.history.providerDetails.createdDate |
                            date:'MM/dd/yyyy'}}</label>
                        </div>
                      </div>
                      <div class="row mt-0 mb-0 ml-1 mr-1">
                        <div class="col-md-12 pt-1 pb-1">
                          <div class="table-responsive">
                            <table class="table table-striped">
                              <thead class="m-b-0 m-t-0">
                                <tr class="d-flex">
                                  <th class="col-sm-1">Type</th>
                                  <th class="col-sm-2">Address<small>(Line 1)</small></th>
                                  <th class="col-sm-2">Address <small>(Line 2)</small></th>
                                  <th class="col-sm-1">City</th>
                                  <th class="col-sm-1">State</th>
                                  <th class="col-sm-1">Zip</th>
                                  <th class="col-sm-1">Phone</th>
                                  <th class="col-sm-1">Fax</th>
                                  <th class="col-sm-2">Office Hours</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="d-flex"
                                  *ngFor="let location of this.currentAndHistoryData$.history.locationDetails">
                                  <td class="col-sm-1 ml-1">{{location.locationType}}</td>
                                  <td class="col-sm-2">{{location.streetAddress1}}</td>
                                  <td class="col-sm-2">{{location.streetAddress2}}</td>
                                  <td class="col-sm-1">{{location.city}}</td>
                                  <td class="col-sm-1">{{location.state}}</td>
                                  <td class="col-sm-1">{{location.zip}}</td>
                                  <td class="col-sm-1">{{location.phone | formatPhone }}</td>
                                  <td class="col-sm-1">{{location.fax | formatPhone }}</td>
                                  <td class="col-sm-2">{{convertTime(location.monHours)}} <div
                                      *ngIf="location.locationType!='Billing'" class="btncircle textCenter m-0"
                                      title="Click to view details"><a
                                        (click)="viewOfficeHoursModal(location.locationId ?? 0, true)"><i
                                          class="fa fa-eye" aria-hidden="true"></i></a></div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <!-- Delete record pop up-->
        <lte-modal #alertConfirmationModal submitButtonLabel="Continue" cancelButtonLabel="Cancel" contentWidth="420px">
          <modal-header>
            <b><i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="color: #ffc107;"></i>
              Confirm</b></modal-header>
          <modal-body> Are you sure you want to save the selection(s)? </modal-body>
        </lte-modal>
      </div>
    </div>
  </lte-layout-content>
  <p class="text-center mt-3">{{copyright}}</p>
</lte-layout-wrapper>
<!-- Add Office Hours Modal-->
<lte-modal #addOfficeHoursModal [submitButtonLabel]="''" [disableSubmitButton]="true" [isFormModal]="true"
  [closeOnOutsideClick]="showReadonlyModal" [cancelButtonLabel]="showReadonlyModal ? '' : 'Cancel'"
  [contentWidth]="modalwidth">
  <modal-header><b>Office Hours</b></modal-header>
  <modal-body>
    <app-add-office-hour #addOfficeHourComponent [isReadonly]="showReadonlyModal"></app-add-office-hour>
  </modal-body>
</lte-modal>

<lte-modal #alertLeavePageModal submitButtonLabel="Leave" cancelButtonLabel="Cancel" contentWidth="420px">
  <modal-header>
    <b><i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="color: #ffc107;"></i> Leave
      Page?</b></modal-header>
  <modal-body>Changes you made may not be saved. </modal-body>
</lte-modal>